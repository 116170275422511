@import "../../../assets/styles/scss/config-styles/vars";

a {
  width: 100%;
}

.button {
  position: relative;

  width: 100%;
  padding: 18px 0;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 8px;
  border: none;

  cursor: pointer;

  font-weight: 700;
  font-size: 20px;
  line-height: 140%;

  transition: color 0.3s, background-color 0.3s, border 0.3s, transform 0.1s;

  user-select: none;

  @media (max-width: $laptop-l-width) {
    padding: 14px 0;
  }

  &:active {
    transform: translateY(2px);
  }
}

.outlined {
  color: $selected-color;
  background-color: transparent;
  border: 2px solid $selected-color;

  &:hover {
    color: $default-text-color;
    background-color: $selected-color;
    border: 2px solid $selected-color;
  }
}

.filled {
  color: $default-text-color;
  background-color: $selected-color;
  border: 2px solid $selected-color;

  &:hover {
    color: $selected-color;
    background-color: transparent;
    border: 2px solid $selected-color;
  }
}
