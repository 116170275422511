@import 'config-styles/reset';
@import 'config-styles/vars';
@import 'config-styles/fonts';

body {
  position: relative;
  background-color: $default-bg-color;
}

body, input, textarea, select, button, option {
  font-family: $default-font-family, 'system-ui';
}

#root {
  overflow: hidden; // If need to use 'position: sticky' - set 'overflow: clip'
  color: $default-text-color;
  font-weight: $default-font-weight;
}

.selectedColor {
  color: $selected-color !important;
}

.socialIcon {
  width: 60px;
  height: 60px;

  cursor: pointer;

  svg {
    path {
      transition: fill 0.3s;
    }
  }

  &:hover {
    #facebook {
      path {
        fill: $facebook-hover-color;
      }
    }
    #instagram {
      path {
        fill: $instagram-hover-color;
      }
    }
    #whatsapp {
      path {
        fill: $whatsapp-hover-color;
      }
    }
  }
}

.label {
  font-weight: 500;
  font-size: 14px;
  line-height: 143%;

  margin-bottom: 6px;
  color: #A7BDD1;
}

.error {
  color: #ff4d4f;
}

.title {
  font-weight: 700;
  font-size: 64px;
  text-transform: uppercase;

  @media (max-width: $laptop-l-width) {
    font-size: 48px;
  }

  @media (max-width: $tablet-width) {
    font-size: 40px;
  }
}

.smallerTitle {
  font-weight: 700;
  font-size: 46px;

  @media (max-width: $tablet-width) {
    font-size: 40px;
  }
}

.text {
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 0.01em;
  color: #fff;

  @media (max-width: $laptop-l-width) {
    font-size: 18px;
  }
}

.iconBorder {
  position: relative;

  width: 40px;
  min-width: 40px;
  height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #2744F0;
  border-radius: 50%;

  &::before {
    @include location-icon-border(60px, #A2B9CF);
  }

  &::after {
    @include location-icon-border(50px, #A9C3FA);
  }
}

@keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

.bgObject {
  position: absolute;
  z-index: -10;
  user-select: none;
  animation: fadeIn 0.5s ease-in-out;
}

.cross {
  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  .line {
    position: absolute;

    width: 100%;
    height: 2px;

    transition: transform 0.3s, background-color 0.3s;
  }

  #line1 {
    transform: rotate(45deg);
  }

  #line2 {
    transform: rotate(-45deg);
  }
}
