@font-face {
  font-family: 'Eudoxus Sans';
  font-weight: 200;
  src: local('Eudoxus Sans'), url(../../../fonts/EudoxusSans-ExtraLight.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Eudoxus Sans';
  font-weight: 300;
  src: local('Eudoxus Sans'), url(../../../fonts/EudoxusSans-Light.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Eudoxus Sans';
  font-weight: 400;
  src: local('Eudoxus Sans'), url(../../../fonts/EudoxusSans-Regular.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Eudoxus Sans';
  font-weight: 500;
  src: local('Eudoxus Sans'), url(../../../fonts/EudoxusSans-Medium.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Eudoxus Sans';
  font-weight: 600;
  src: local('Eudoxus Sans'), url(../../../fonts/EudoxusSans-Bold.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Eudoxus Sans';
  font-weight: 700;
  src: local('Eudoxus Sans'), url(../../../fonts/EudoxusSans-ExtraBold.ttf) format('truetype');
  font-display: swap;
}
