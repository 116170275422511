@import '../../../assets/styles/scss/config-styles/vars';

.header {
  position: absolute;
  left: $aside-socials-width;
  top: 0;

  width: calc(100% - $aside-socials-width);

  display: flex;
  justify-content: space-between;
  align-items: center;

  z-index: 10;

  @media (max-width: $tablet-width) {
    position: static;
    width: 100%;
    //height: 80px;

    padding: 30px;
  }

  .burger {
    display: none;

    @media (max-width: $tablet-width) {
      display: flex;
      z-index: 11;
    }
  }

  .nav {
    display: flex;
    column-gap: 40px;

    padding: 30px 30px 30px 64px;

    @media (max-width: $laptop-l-width) {
      padding-left: 40px;
    }

    @media (max-width: $tablet-width) {
      display: none;
    }

    .navElem {
      color: $default-text-color;

      font-size: 24px;
      outline: none;
      white-space: nowrap;

      transition: color 0.3s;

      &:hover {
        color: $selected-color;
      }
    }
  }

  .logo {
    flex-shrink: 0;
    margin: 60px 60px 0 0;

    @media (max-width: $tablet-width) {
      width: 62px;
      height: 48px;
      margin: 0;
      z-index: 11;
    }
  }

  .logout {
    font-size: 16px;
    width: 100px;
    margin-right: auto;
    padding: 12px 0;

    @media (max-width: $tablet-width) {
      left: 70px;
    }
  }
}
