.burger {
  width: 40px;
  height: 30px;

  position: relative;

  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;

  cursor: pointer;

  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 50%;
    background: white;
    opacity: 1;
    transform: rotate(0deg);
    transition: .35s ease-in-out;

    &:nth-child(even) {
      left: 50%;
      border-radius: 0 9px 9px 0;
    }

    &:nth-child(odd) {
      left: 0px;
      border-radius: 9px 0 0 9px;
    }

    &:nth-child(1), &:nth-child(2) {
      top: 0px;
    }

    &:nth-child(3), &:nth-child(4) {
      top: 12px;
    }

    &:nth-child(5), &:nth-child(6) {
      top: 24px;
    }
  }

  &.open {
    span {
      &:nth-child(1), &:nth-child(6) {
        transform: rotate(45deg);
      }

      &:nth-child(2), &:nth-child(5) {
        transform: rotate(-45deg);
      }

      &:nth-child(1) {
        left: 3px;
        top: 8px;
      }

      &:nth-child(2) {
        left: calc(50% - 3px);
        top: 8px;
      }

      &:nth-child(3) {
        left: -50%;
        opacity: 0;
      }

      &:nth-child(4) {
        left: 100%;
        opacity: 0;
      }

      &:nth-child(5) {
        left: 3px;
        top: 22px;
      }

      &:nth-child(6) {
        left: calc(50% - 3px);
        top: 22px;
      }
    }
  }
}




/*.burger {
  width: 40px;
  height: 40px;

  padding: 9px 5px;

  display: flex;
  flex-direction: column;
  row-gap: 10px;

  cursor: pointer;

  &:hover {
    .line {
      background-color: white;
    }
  }

  &.open {
    .line:nth-child(1) {
      transform: rotate(45deg) translateY(15px);
    }

    .line:nth-child(2) {
      opacity: 0;
    }

    .line:nth-child(3) {
      transform: rotate(-45deg) translateY(-15px);
    }
  }

  .line {
    width: 100%;
    height: 1px;
    border-radius: 2px;
    background-color: white;

    transition: all 0.3s;
  }
}*/
