.wrapper {
  width: 100%;
  height: 100%;

  .spinner {
    content: "";
    position: absolute;

    width: 30px;
    height: 30px;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    margin: auto;

    border: 3px solid transparent;
    border-left-color: inherit;
    border-radius: 50%;

    animation: button-loading-spinner 0.75s ease-in-out infinite;
  }
}



@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

