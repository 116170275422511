@import '../../../assets/styles/scss/config-styles/vars';

.container {
  width: $aside-socials-width;
  height: $aside-socials-height;

  padding: 55px 64px 180px 55px;

  backdrop-filter: blur(100px);

  position: relative;
  z-index: 5;

  .socials {
    position: relative;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .icons {
      position: absolute;
      top: 200px;

      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 16px;

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;

        outline: none;
      }
    }

    .text {
      position: absolute;
      bottom: 200px;

      font-family: inherit;
      font-size: 24px;
      line-height: 32px;
      color: $default-text-color;

      white-space: nowrap;

      transform: rotate(-90deg);
    }
  }
}
