@import "../../../assets/styles/scss/config-styles/vars";

.mobileSideMenu {
  display: none;

  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
  left: -100%;
  overflow: hidden;

  backdrop-filter: blur(60px);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(153, 153, 153, 0.05) 100%);

  z-index: 10;

  transition: opacity 0.3s, visibility 0.5s, left 0.5s;

  @media (max-width: $tablet-width) {
    display: block;
  }

  .nav {
    height: calc(100% - $nav-margin-top * 2);
    margin-top: 104px;
    padding: 0 20px 36px 20px;

    overflow-y: auto;
    scrollbar-width: none;

    @media (max-width: $tablet-width) {
      height: calc(100% - $nav-margin-top * 2.5);
    }
    @media (max-width: $mobile-width) {
      height: calc(100% - $nav-margin-top * 2);
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 32px;

      .link {
        color: $default-text-color;

        font-size: 24px;
        outline: none;
        white-space: nowrap;

        transition: color 0.3s;

        &:hover {
          color: $selected-color;
        }
      }
    }
    .socials {
      display: none;

      @media (max-width: $tablet-width) {
        position: absolute;
        bottom: 10px;
        left: 40px;
        display: flex;
        align-items: center;
        gap: 20px;
      }

      @media (max-width: $mobile-width) {
        display: none;
      }

      .text {
        font-size: 24px;
        line-height: 133%;
        margin-right: 40px;
      }

      .icons {
        display: flex;
        column-gap: 20px;

        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}
